import { createClient } from "../../utils/supabase/client";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ActiveSubscription from "./ActiveSubscription";
import { useNavigate } from "react-router";
import Logo from "../../assets/logo.png";
import Pricing from "./Pricing";

export default function Subscriptions() {
  const supabase = createClient();
  const [subscription, setSubscription] = useState(null);
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();

  const onPageLoad = async () => {
    setIsLoading(true);

    //get the active user
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      setUser(user);
    }

    if (!user) {
      //if user is not logged in, redirect them to the sign in page

      navigate("sign-in");
      return;
    }

    //get the user's subscription
    const { data: subscription, error } = await supabase
      .from("subscriptions")
      .select("*, prices(*, products(*))")
      .in("status", ["trialing", "active"])
      .maybeSingle();
    if (error) {
      setIsLoading(false);
    }

    console.log(subscription);

    if (subscription) {
      console.log(subscription);
      setSubscription(subscription);
    }

    //get all of the subscriptions available
    const { data: products } = await supabase
      .from("products")
      .select("*, prices(*)")
      .eq("active", true)
      .eq("prices.active", true)
      .order("metadata->index")
      .order("unit_amount", { referencedTable: "prices" });

    setProducts(products);
    setIsLoading(false);
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (subscription && user) {
    return (
      <div>
        <ActiveSubscription subscription={subscription} user={user} />
      </div>
    );
  } else {
    //page shown to users not subscribed
    return (
      <div className="flex flex-col min-h-screen" data-theme="dark">
        <div className="hero flex-1">
          <div className="hero-content flex-col">
            <img src={Logo} alt="company logo" className="rounded-lg w-32" />
            <div className="text-center"></div>

            <Pricing
              user={user}
              products={products ?? []}
              subscription={subscription}
            />
          </div>
        </div>
      </div>
    );
  }
}
