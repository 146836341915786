import { useState } from "react";
import { createClient } from "../../utils/supabase/client";
import { useNavigate } from "react-router";
import Snackbar from "../../components/Snackbar/Snackbar";
import { useSnackbar } from "../../hooks/useSnackbar";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { getStripe } from "../../utils/stripe/client";

export default function Pricing({ user, products, subscription }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar, message, triggerSnackbar } = useSnackbar();

  const supabase = createClient();

  const handleStripeCheckout = async (price) => {
    console.log("pricing.js price", price);
    setIsLoading(true);

    //get jwt token for the user that is logged in
    const userToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const resp = await fetch("/api/stripe/buyPlan", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({ price }),
    });

    const json = await resp.json();
    console.log("pricing.js json", json);
    if (resp.ok) {
      //redirect the customer to the checkout page if there are no errors
      const { sessionId } = json;
      console.log("pricing.js sessionId", sessionId);
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
      setIsLoading(false);
    } else {
      //error creating checkout session, so show snackbar
      triggerSnackbar(json.error);
      setIsLoading(false);
      return;
    }
  };

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem("supabase.auth.token");
    localStorage.removeItem("supabase.auth.expires_at");
    window.location.href = window.location.origin;
  };

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!products.length) {
    return (
      <>
        <section className="h-screen" data-theme="dark">
          <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center"></div>
            <p className="text-4xl font-extrabold text-white sm:text-center sm:text-6xl">
              No subscriptions available.
            </p>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section data-theme="dark">
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="mt-2 space-y-4 sm:mt-16 sm:space-y-0 flex flex-wrap justify-center gap-6 w-full">
              {products.map((product) => {
                const price = product?.prices?.find(
                  (price) => price.interval === "month"
                );

                if (!price) return null;
                const priceString = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: price.currency,
                  minimumFractionDigits: 0,
                }).format((price?.unit_amount || 0) / 100);

                return (
                  <div
                    key={product.id}
                    className={`flex flex-col rounded-lg shadow-sm divide-y divide-gray-600 bg-gray-800 ${
                      subscription
                        ? product.name === subscription?.prices?.products?.name
                          ? "border border-pink-500"
                          : ""
                        : product.name === "Freelancer"
                        ? "border border-pink-500"
                        : ""
                    } flex-1 basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/4 max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto`}
                  >
                    <div className="flex flex-col h-full p-6 w-64">
                      <h2 className="text-2xl font-semibold leading-6 text-white">
                        {product.name}
                      </h2>
                      <p className="mt-4 text-gray-300 flex-grow overflow-y-auto max-h-20">
                        {product.description}
                      </p>
                      <div className="mt-auto">
                        <p className="mt-8">
                          <span className="text-4xl font-extrabold text-gray-300">
                            {priceString}
                          </span>
                          <span className="text-base font-medium text-gray-100">
                            / month
                          </span>
                        </p>
                        <button
                          className="mt-5 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                          onClick={() => handleStripeCheckout(price)}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {!user ? (
              <div className="text-center mt-3">
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  onClick={() => navigate("/sign-in")}
                >
                  Sign In
                </button>
              </div>
            ) : (
              <div className="mt-3 text-center">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 mt-5"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            )}
          </div>
        </section>

        {showSnackbar && <Snackbar message={message} />}
      </>
    );
  }
}
