import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Headline from "../Headline/Headline";

const TermsAndConditions = () => {
  return (
    <div className="mt-14">
      <Header />
      <Headline text="Terms and conditions" />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h2 className="text-xl font-bold mt-6 mb-2">Article 1: Introduction</h2>
        <p className="mb-4">
          <strong>Acceptance of Terms</strong> Welcome to Swiftlead! By
          accessing our website, swiftlead.io, and using our LinkedIn automation
          tool ("Tool"), collectively referred to as our "Services," you, the
          user ("User" or "you"), agree to be legally bound by these Terms of
          Use ("Terms"). This Agreement governs your access to and use of our
          Services, and it constitutes a legally binding agreement between you
          and Swiftlead LLC ("Swiftlead," "we," "us," or "our"). If you do not
          agree with all of these Terms, you are expressly prohibited from using
          the Services and you must discontinue use immediately.
        </p>
        <p className="mb-4">
          <strong>Modifications to Terms</strong> Swiftlead reserves the right
          to revise and update these Terms at any time without prior notice to
          you. Changes will take effect immediately upon their posting on the
          website. Your continued use of our Services after such changes have
          been posted signifies your acceptance of the updated Terms. We
          encourage you to review the Terms periodically to ensure that you are
          aware of our latest updates.
        </p>
        <p className="mb-4">
          <strong>Capacity to Contract</strong> By using our Services, you
          affirm that you are at least 18 years old, or the legal age of
          majority in your jurisdiction, and are fully able and competent to
          enter into the terms, conditions, obligations, affirmations,
          representations, and warranties set forth in these Terms, and to abide
          by and comply with these Terms.
        </p>
        <p className="mb-4">
          <strong>Compliance with Local Laws</strong> You are responsible for
          ensuring that your use of our Services complies with all laws, rules,
          and regulations applicable to you. It is your responsibility to ensure
          that accessing and using Swiftlead’s website and Tool is legal in your
          jurisdiction. If your use of our Services is contrary to the laws in
          your area, you must stop using our Services immediately.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 2: Services Provided
        </h2>
        <p className="mb-4">
          <strong>Scope of Services</strong> Swiftlead provides a LinkedIn
          automation tool designed to facilitate and enhance various LinkedIn
          activities, including but not limited to sending personalized messages
          to other LinkedIn users. The Tool is crafted to assist users in
          efficient networking and outreach on LinkedIn.
        </p>
        <p className="mb-4">
          <strong>Lawful Use</strong> The Tool is intended strictly for lawful
          purposes and must be used in compliance with all applicable laws and
          regulations, including LinkedIn's own terms of service. Users must
          ensure that their use of the Tool adheres to all relevant legal
          standards and ethical guidelines.
        </p>
        <p className="mb-4">
          <strong>LinkedIn Message Limits</strong> LinkedIn imposes specific
          limits on the number of open profile messages users can send each
          month. Our Tool operates within these established limits to provide
          messaging capabilities. It's important to note that any open profile
          messages sent independently of the Tool also count towards your
          LinkedIn limit. Therefore, by using our Tool, you acknowledge and
          agree to adhere to LinkedIn's messaging restrictions. Swiftlead does
          not extend beyond these limits for open profile messages if you exceed
          them through other methods.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 3: User Obligations
        </h2>
        <p className="mb-4">
          <strong>Legal Compliance</strong> You are obligated to use the Tool
          responsibly and within the bounds of all applicable laws, guidelines,
          and LinkedIn's terms of service. Your use of the Tool should not
          infringe upon the rights of others or the operational integrity of
          LinkedIn.
        </p>
        <p className="mb-4">
          <strong>Account Security</strong> You are solely responsible for
          maintaining the integrity and security of your account credentials,
          including your username and password. You must take reasonable steps
          to prevent unauthorized access to your account. In the event of any
          suspected security breach, you are required to notify Swiftlead
          immediately.
        </p>
        <p className="mb-4">
          <strong>Accuracy of Information</strong> When registering for an
          account and during all subsequent interactions with our Services, you
          agree to provide truthful, accurate, current, and complete information
          as requested. You are responsible for maintaining and promptly
          updating your account information to keep it accurate, current, and
          complete.
        </p>
        <p className="mb-4">
          <strong>Feedback and Suggestions</strong> Swiftlead values and
          welcomes feedback and suggestions regarding our Services. By providing
          any feedback, ideas, or suggestions ("Feedback") to Swiftlead, you
          acknowledge and agree that:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>1.</strong> All Feedback provided to Swiftlead becomes the
            exclusive intellectual property of Swiftlead.
          </li>
          <li>
            <strong>2.</strong> Swiftlead may use, disclose, reproduce, license,
            and otherwise exploit the Feedback provided for any purpose, without
            restriction or compensation to you.
          </li>
          <li>
            <strong>3.</strong> Swiftlead has no obligation to implement any
            Feedback provided.
          </li>
        </ul>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 4: Prohibited Uses
        </h2>
        <p className="mb-4">
          <strong>General Restrictions</strong> You are prohibited from using
          the Tool for any unlawful or unauthorized purposes. The following,
          while not exhaustive, outlines specific prohibited actions:
        </p>
        <p className="mb-4">
          <strong>Legal and Policy Violations</strong>
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>1.</strong> Engaging in any activity that violates
            applicable laws, regulations, or LinkedIn’s terms of service.
          </li>
          <li>
            <strong>2.</strong> Using the Tool in a manner that could damage,
            disable, overburden, or impair the Tool or interfere with any other
            party's use and enjoyment of the Tool.
          </li>
        </ul>
        <p className="mb-4">
          <strong>Abusive Practices</strong>
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>1.</strong> Transmitting spam, including unsolicited or
            unauthorized advertising, promotional materials, junk mail, chain
            letters, or any other form of solicitation.
          </li>
          <li>
            <strong>2.</strong> Impersonating any person or entity, or falsely
            stating or otherwise misrepresenting your affiliation with a person
            or entity.
          </li>
        </ul>
        <p className="mb-4">
          <strong>Misinformation</strong>
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>1.</strong> Providing false, inaccurate, or misleading
            information through the Tool.
          </li>
          <li>
            <strong>2.</strong> Manipulating identifiers in order to disguise
            the origin of any message or post transmitted through the Tool.
          </li>
        </ul>

        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 5: Prohibition of Reverse Engineering and Competition
        </h2>
        <p className="mb-4">
          <strong>Prohibition of Decompilation and Disassembly</strong> Users
          are strictly prohibited from reverse engineering, decompiling, or
          disassembling the Tool’s code provided by our company. This includes
          any attempt to recreate, analyze, or derive the source code,
          algorithms, data structures, or other underlying aspects of the Tool.
        </p>
        <p className="mb-4">
          <strong>Restricted Activities</strong> Without limiting the generality
          of the foregoing, Users shall not:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>1.</strong> Modify or Alter the Tool’s Code: Attempt to
            change, alter, or modify any part of the Tool’s code, including but
            not limited to altering its behavior, features, or functions.
          </li>
          <li>
            <strong>2.</strong> Extract Source Code: Use any techniques to
            derive or obtain the source code from the object code or executable
            code of the Tool.
          </li>
          <li>
            <strong>3.</strong> Use Debugging Tools: Employ debugging tools or
            techniques to examine, analyze, or monitor the internal operations
            of the Tool.
          </li>
          <li>
            <strong>4.</strong> Create Derivative Works: Develop or create any
            derivative works based on the Tool’s code, including but not limited
            to software, applications, or programs that incorporate, emulate, or
            use the Tool’s code.
          </li>
          <li>
            <strong>5.</strong> Circumvent Security Features: Attempt to bypass,
            disable, or circumvent any security measures, encryption, or other
            protection mechanisms implemented within the Tool.
          </li>
        </ul>
        <p className="mb-4">
          <strong>Intellectual Property Protection</strong> All intellectual
          property rights related to the Tool, including but not limited to
          copyrights, patents, trade secrets, and trademarks, remain the
          exclusive property of our company. Users shall not engage in any
          activity that infringes upon or misappropriates these intellectual
          property rights.
        </p>
        <p className="mb-4">
          <strong>Non-Competition</strong> Users agree not to compete with our
          company by developing, releasing, marketing, or selling any similar
          products or services that directly or indirectly compete with the
          Tool. This includes, but is not limited to, any software, application,
          or service that performs the same or similar functions as the Tool or
          targets the same market as the Tool.
        </p>
        <p className="mb-4">
          <strong>Legal Consequences</strong> Violation of this prohibition may
          result in severe legal consequences, including but not limited to
          civil and criminal liability. Our company reserves the right to pursue
          all available legal remedies in the event of such a violation,
          including seeking injunctive relief, damages, and attorney’s fees.
        </p>
        <p className="mb-4">
          <strong>Compliance</strong> Users agree to comply with this
          prohibition and acknowledge that adherence to this section is a
          material condition of their use of the Tool. Any breach of this
          section may result in the immediate termination of their access to the
          Tool and any associated services.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 6: Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          <strong>"As Is" Basis</strong> Swiftlead provides the LinkedIn
          automation tool ("Tool") on an "as is" and "as available" basis
          without any warranties of any kind, either express or implied,
          including but not limited to warranties of merchantability, fitness
          for a particular purpose, or non-infringement. Swiftlead expressly
          disclaims all warranties, whether expressed or implied, related to the
          Tool, including its quality, accuracy, reliability, suitability, or
          availability.
        </p>
        <p className="mb-4">
          <strong>No Guarantee of Outcomes</strong> Swiftlead makes no warranty
          that the Tool will meet your requirements or achieve any intended
          results, or that the operation of the Tool will be uninterrupted or
          error-free. We also do not guarantee that the Tool or any services
          associated therewith will always be available or are free of malware
          or other harmful components.
        </p>
        <p className="mb-4">
          <strong>Reliance at User's Risk</strong> Any material downloaded or
          otherwise obtained through the use of the Tool is done at your own
          discretion and risk. You will be solely responsible for any damage to
          your computer system, mobile device, or data that results from the
          download or use of any such material.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 7: Limitation of Liability
        </h2>
        <p className="mb-4">
          <strong>General Limitations</strong> In no event shall Swiftlead, its
          officers, directors, employees, or agents be liable for any indirect,
          incidental, special, consequential, or punitive damages, or any
          damages whatsoever, including but not limited to damages for loss of
          profits, goodwill, use, data or other intangible losses (even if
          Swiftlead has been advised of the possibility of such damages),
          arising out of or in connection with the use or the inability to use
          the Tool.
        </p>
        <p className="mb-4">
          <strong>Scope of Liability</strong> This limitation of liability
          applies whether the alleged liability is based on contract, tort,
          negligence, strict liability, or any other basis, even if Swiftlead
          has been advised of the possibility of such damage. Because some
          jurisdictions do not allow the exclusion or limitation of incidental
          or consequential damages, Swiftlead's liability in such jurisdictions
          shall be limited to the extent permitted by law.
        </p>
        <p className="mb-4">
          <strong>Liability Cap</strong> Without limiting the generality of the
          foregoing, Swiftlead's total liability to you for all losses, damages,
          and causes of action (in contract or tort, including but not limited
          to, negligence or otherwise) will not exceed the amount you paid to
          access or use the Tool.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 8: Modification of Terms
        </h2>
        <p className="mb-4">
          <strong>Right to Modify Terms</strong> Swiftlead reserves the
          unilateral right to modify, amend, or update these Terms of Use at any
          time and for any reason without prior notice to you. Such
          modifications will take effect immediately upon posting on the
          Swiftlead website or direct communication to you via email or through
          the Tool.
        </p>
        <p className="mb-4">
          <strong>User's Responsibility to Review Terms</strong> It is your
          responsibility to periodically review these Terms to stay informed of
          updates. Your continued use of the Tool after such modifications will
          constitute your acknowledgment of the modified Terms and agreement to
          abide and be bound by the updated Terms.
        </p>
        <p className="mb-4">
          <strong>Effect of Changes on Existing Users</strong> Any changes to
          these Terms will apply immediately to new users and, following their
          posting or notification, to current users. If you do not agree with
          the modified Terms, you must discontinue using the Swiftlead services
          immediately.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">Article 9: Termination</h2>
        <p className="mb-4">
          <strong>Termination by Swiftlead</strong> Swiftlead may terminate your
          access to the Tool and your account, at its sole discretion, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms. Upon termination, your
          right to use the Tool will cease immediately.
        </p>
        <p className="mb-4">
          <strong>Consequences of Termination</strong> Upon termination,
          Swiftlead reserves the right to delete all your data, information, and
          files stored on our servers associated with your use of the Tool.
          Swiftlead is not liable for any loss or damage following or arising
          from the termination of your account.
        </p>
        <p className="mb-4">
          <strong>User's Right to Terminate</strong> You may terminate your
          account and discontinue your use of the Tool at any time by following
          the account termination procedures made available through the Tool, or
          by contacting Swiftlead support directly.
        </p>
        <p className="mb-4">
          <strong>Survival of Certain Provisions</strong> Notwithstanding
          termination, the provisions of these Terms that by their nature should
          survive termination shall remain in effect, including, but not limited
          to, ownership provisions, warranty disclaimers, indemnity, and
          limitations of liability.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 10: Governing Law
        </h2>
        <p className="mb-4">
          <strong>Jurisdiction</strong> These Terms and any action related
          thereto shall be governed by and construed in accordance with the laws
          of the State of Michigan, United States of America, without giving
          effect to any principles of conflicts of law. By using the Tool, you
          agree that the laws of Michigan will govern these Terms and any
          dispute of any sort that might arise between you and Swiftlead.
        </p>
        <p className="mb-4">
          <strong>Legal Proceedings</strong> Any legal suit, action, or
          proceeding arising out of, or related to, these Terms or the Services
          provided by Swiftlead shall be instituted exclusively in the federal
          courts of the United States or the courts of the State of Michigan.
          You waive any and all objections to the exercise of jurisdiction over
          you by such courts and to venue in such courts.
        </p>
        <p className="mb-4">
          <strong>International Use</strong> We make no claims that the Tool or
          any of its content is accessible or appropriate outside of the United
          States. Access to the Tool may not be legal by certain persons or in
          certain countries. If you access the Tool from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 11: Contact Information
        </h2>
        <p className="mb-4">
          <strong>Queries and Concerns</strong> For any questions, concerns, or
          comments about these Terms, the Services, or any other inquiries,
          please contact Swiftlead via email at{" "}
          <a
            href="mailto:support@swiftlead.io"
            className="text-blue-500 hover:underline"
          >
            support@swiftlead.io
          </a>
          .
        </p>
        <p className="mb-4">
          <strong>Customer Support</strong> Our dedicated customer support team
          is available to assist you with any issues or questions you may have
          regarding your use of the Tool. We strive to provide timely and
          supportive assistance to ensure your satisfaction with our services.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
